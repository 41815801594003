import React, { useState, useEffect } from 'react';
import { fetchAllRepsForSchool, inviteNewRepToSchool, deleteRepAtSchool, updateRepAccessSettings } from '../../../api/reps';
import { CircularProgress, Alert, Button, Snackbar } from '@mui/material';

import { logErrorToConsole, logErrorToSentryWithContext } from '../../../utils/errorLogging';

const MemberSettings = ({ activeSchool, user }) => {
  const [isGetMembersApiCallLoading, setIsGetMembersApiCallLoading] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isInviteApiCallLoading, setIsInviteApiCallLoading] = useState(false);
  const [inviteApiError, setInviteApiError] = useState(null);

  const [inviteFirstName, setInviteFirstName] = useState('');
  const [inviteLastName, setInviteLastName] = useState('');
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteAccessSettings, setInviteAccessSettings] = useState({
    has_certifications_access: false,
    has_repayments_access: false,
    has_accounting_access: false,
    has_user_management_access: false
  });
  const [fieldError, setFieldError] = useState({ firstName: '', lastName: '', email: '', role: '' });

  const [memberToRemove, setMemberToRemove] = useState(null);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isRemoveApiCallLoading, setIsRemoveApiCallLoading] = useState(false);
  const [removeApiError, setRemoveApiError] = useState(null);

  const [loadingStates, setLoadingStates] = useState({});
  const [isErrorSnackbarOpen, setIsErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [canManageOtherUsers, setCanManageOtherUsers] = useState(activeSchool.has_user_management_access);

  const loadReps = async () => {
    try {
      setIsGetMembersApiCallLoading(true);
      const data = await fetchAllRepsForSchool(activeSchool.id);
      let reps = data.reps;
      if (process.env.REACT_APP_APP_ENV === 'prd') {
        reps = reps.filter(rep => !rep.email.includes("@fortifyeducation.com"))
      }
      setTeamMembers(reps);

      const currentUserData = reps.find(rep => rep.email === user.email);
      if (currentUserData) {
        setCanManageOtherUsers(currentUserData.has_user_management_access);
      }
    } catch (error) {
      logErrorToConsole("Error fetching reps data", error);
      logErrorToSentryWithContext(error, {
        schoolId: activeSchool.id,
      });
    } finally {
      setIsGetMembersApiCallLoading(false);
    }
  };

  useEffect(() => {
    if (activeSchool && activeSchool.id) {
      loadReps();
    }
  }, [activeSchool]);

  const handleInviteClick = () => {
    setIsInviteModalOpen(true);
  };

  const handleCloseInviteModal = () => {
    setIsInviteModalOpen(false);
    setInviteFirstName('');
    setInviteLastName('');
    setInviteEmail('');
    setInviteAccessSettings({
      has_certifications_access: false,
      has_repayments_access: false,
      has_accounting_access: false,
      has_user_management_access: false
    });
    setFieldError({ firstName: '', lastName: '', email: '', role: '' });
  };

  const handleInviteFirstNameChange = (e) => {
    setInviteFirstName(e.target.value);
    setFieldError({ ...fieldError, firstName: '' });
  };

  const handleInviteLastNameChange = (e) => {
    setInviteLastName(e.target.value);
    setFieldError({ ...fieldError, lastName: '' });
  };

  const handleInviteEmailChange = (e) => {
    setInviteEmail(e.target.value);
    setFieldError({ ...fieldError, email: '' });
  };

  const handleExecuteInvite = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (inviteFirstName.length < 3) {
      setFieldError({ ...fieldError, firstName: 'First name must be at least 3 characters long.' });
    } else if (inviteLastName.length < 3) {
      setFieldError({ ...fieldError, lastName: 'Last name must be at least 3 characters long.' });
    } else if (!emailRegex.test(inviteEmail)) {
      setFieldError({ ...fieldError, email: 'Please enter a valid email address.' });
    } else {
      try {
        setIsInviteApiCallLoading(true);
        setInviteApiError('');
        await inviteNewRepToSchool(activeSchool.id, {
          first_name: inviteFirstName,
          last_name: inviteLastName,
          email: inviteEmail,
          ...inviteAccessSettings,
        });
        setIsInviteModalOpen(false);
        setInviteFirstName('');
        setInviteLastName('');
        setInviteEmail('');
        setInviteAccessSettings({
          has_certifications_access: false,
          has_repayments_access: false,
          has_accounting_access: false,
          has_user_management_access: false
        });
        loadReps();
      } catch (error) {
        logErrorToConsole("Failed to invite new rep to school", error);
        logErrorToSentryWithContext(error, {
          schoolId: activeSchool.id,
          inviteFirstName,
          inviteLastName,
          inviteEmail,
          inviteAccessSettings,
        });

        setInviteApiError(error?.response?.data?.error || "There was an error inviting this rep.");
      } finally {
        setIsInviteApiCallLoading(false);
      }
    }
  };

  const handleRemoveClick = (member) => {
    setMemberToRemove(member);
    setIsRemoveModalOpen(true);
  };

  const handleCloseRemoveModal = () => {
    setIsRemoveModalOpen(false);
    setMemberToRemove(null);
  };

  const handleExecuteRemove = async () => {
    setIsRemoveApiCallLoading(true);
    try {
      setRemoveApiError('');
      await deleteRepAtSchool(activeSchool.id, memberToRemove.rep_id);
      setIsRemoveModalOpen(false);
      setMemberToRemove(null);
      loadReps();
    } catch (error) {
      logErrorToConsole("Error removing rep", error);
      logErrorToSentryWithContext(error, {
        schoolId: activeSchool.id,
        repId: memberToRemove.rep_id,
      });

      setRemoveApiError(error?.response?.data?.error || "There was an error removing this rep.")
    } finally {
      setIsRemoveApiCallLoading(false);
    }
  };

  const handleOutsideClick = (e, closeModal) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const handleAccessChange = async (repId, setting, newValue) => {
    const loadingKey = `${repId}-${setting}`;
    setLoadingStates(prev => ({ ...prev, [loadingKey]: true }));
    
    try {
      await updateRepAccessSettings(activeSchool.id, repId, { [setting]: newValue });
      if (repId === user.rep_id && setting === 'has_user_management_access' && !newValue) {
        setCanManageOtherUsers(false);
      }
      loadReps();
    } catch (error) {
      logErrorToSentryWithContext(error, { repId, setting, newValue });
      setErrorMessage(error?.response?.data?.error || "Failed to update access settings. Please try again.");
      setIsErrorSnackbarOpen(true);
    } finally {
      setLoadingStates(prev => ({ ...prev, [loadingKey]: false }));
    }
  };

  const sortedTeamMembers = teamMembers.sort((a, b) => {
    if (a.email === user.email) return -1;
    if (b.email === user.email) return 1;
    return 0;
  });

  return (
    <div className="member-settings-container">
      <div className="member-settings-header">
        <h1 className="page-title">Team Members</h1>
        {canManageOtherUsers && (
          <button
            className="bg-fortify-green text-white px-4 py-2 rounded flex items-center"
            onClick={handleInviteClick}
          >
            <img src="/add_user.svg" alt="Add User" className="w-5 h-5 mr-2" />
            Invite
          </button>
        )}
      </div>

      <div className="bg-gray-100 rounded-t-lg border border-gray-300 p-4">
        <span className="text-gray-700 font-bold">Members</span>
      </div>

      {isGetMembersApiCallLoading ? (
        <div className="min-w-full bg-white border border-gray-300 h-60 flex items-center justify-center">
          <CircularProgress size={20}/>
        </div>
      ) : teamMembers.length === 0 ? (
        <div className="min-w-full bg-white border border-gray-300 h-60 flex items-center justify-center text-gray-700">
          No team members yet
        </div>
      ) : (
        <div className="member-table-container">
          <table className="member-table">
            <thead>
              <tr>
                <th className="text-left px-4 py-2 text-xs border-b border-gray-300">Member</th>
                <th className="text-center px-4 py-2 text-xs border-b border-gray-300">Certifications</th>
                <th className="text-center px-4 py-2 text-xs border-b border-gray-300">Repayments</th>
                <th className="text-center px-4 py-2 text-xs border-b border-gray-300">Accounting</th>
                <th className="text-center px-4 py-2 text-xs border-b border-gray-300">User Management</th>
                <th className="text-center px-4 py-2 text-xs border-b border-gray-300">Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedTeamMembers.map((member, index) => (
                <tr key={index}>
                  <td className="member-info-cell">
                    <img src="/person.svg" alt="Profile" className="w-8 h-8 rounded-full mr-4" />
                    <div>
                      <div className="text-xl text-black">
                        {`${member.first_name} ${member.last_name}`}{!member.created_password_at && ' (Invited)'}
                        {member.email === user.email && ' (You)'}
                      </div>
                      <div className="text-xs text-gray-500">{member.email}</div>
                    </div>
                  </td>
                  <td className="member-role-cell text-center">
                    <input
                      type="checkbox"
                      checked={member.has_certifications_access}
                      onChange={() => handleAccessChange(
                        member.rep_id,
                        'has_certifications_access',
                        !member.has_certifications_access
                      )}
                      disabled={!canManageOtherUsers || loadingStates[`${member.rep_id}-has_certifications_access`]}
                    />
                  </td>
                  <td className="member-role-cell text-center">
                    <input
                      type="checkbox"
                      checked={member.has_repayments_access}
                      onChange={() => handleAccessChange(
                        member.rep_id,
                        'has_repayments_access',
                        !member.has_repayments_access
                      )}
                      disabled={!canManageOtherUsers || loadingStates[`${member.rep_id}-has_repayments_access`]}
                    />
                  </td>
                  <td className="member-role-cell text-center">
                    <input
                      type="checkbox"
                      checked={member.has_accounting_access}
                      onChange={() => handleAccessChange(
                        member.rep_id,
                        'has_accounting_access',
                        !member.has_accounting_access
                      )}
                      disabled={!canManageOtherUsers || loadingStates[`${member.rep_id}-has_accounting_access`]}
                    />
                  </td>
                  <td className="member-role-cell text-center">
                    <input
                      type="checkbox"
                      checked={member.has_user_management_access}
                      onChange={() => handleAccessChange(
                        member.rep_id,
                        'has_user_management_access',
                        !member.has_user_management_access
                      )}
                      disabled={!canManageOtherUsers || loadingStates[`${member.rep_id}-has_user_management_access`]}
                    />
                  </td>
                  <td className="member-actions-cell text-center">
                    {canManageOtherUsers && member.email !== user.email && (
                      <button
                        className="remove-button"
                        onClick={() => handleRemoveClick(member)}
                      >
                        <img src="/remove.svg" alt="Remove User" className="w-3 h-3 mr-2" />
                        Remove
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isInviteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={(e) => handleOutsideClick(e, handleCloseInviteModal)}>
          <div className="bg-white rounded-lg p-8 w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4">Invite a team member</h2>

            {fieldError.firstName && <p className="text-red-500 text-sm mb-2">{fieldError.firstName}</p>}
            <div className="flex space-x-4 mb-4">
              <input
                type="text"
                placeholder="First Name"
                value={inviteFirstName}
                onChange={handleInviteFirstNameChange}
                className="w-1/2 p-2 border border-gray-300 rounded"
              />
              <input
                type="text"
                placeholder="Last Name"
                value={inviteLastName}
                onChange={handleInviteLastNameChange}
                className="w-1/2 p-2 border border-gray-300 rounded"
              />
            </div>

            {fieldError.email && <p className="text-red-500 text-sm mb-2">{fieldError.email}</p>}
            <input
              type="email"
              placeholder="Email"
              value={inviteEmail}
              onChange={handleInviteEmailChange}
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />

            <div className="mb-4">
              <div className="flex flex-col space-y-2">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={inviteAccessSettings.has_certifications_access}
                    onChange={(e) => setInviteAccessSettings({
                      ...inviteAccessSettings,
                      has_certifications_access: e.target.checked
                    })}
                    className="mr-2"
                  />
                  Certifications Access
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={inviteAccessSettings.has_repayments_access}
                    onChange={(e) => setInviteAccessSettings({
                      ...inviteAccessSettings,
                      has_repayments_access: e.target.checked
                    })}
                    className="mr-2"
                  />
                  Repayments Access
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={inviteAccessSettings.has_accounting_access}
                    onChange={(e) => setInviteAccessSettings({
                      ...inviteAccessSettings,
                      has_accounting_access: e.target.checked
                    })}
                    className="mr-2"
                  />
                  Accounting Access
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={inviteAccessSettings.has_user_management_access}
                    onChange={(e) => setInviteAccessSettings({
                      ...inviteAccessSettings,
                      has_user_management_access: e.target.checked
                    })}
                    className="mr-2"
                  />
                  User Management Access
                </label>
              </div>
            </div>

            {inviteApiError && <Alert severity="error" style={{ marginBottom: '1rem' }}>{inviteApiError}</Alert>}

            <div className="flex space-x-4 justify-center">
              {isInviteApiCallLoading ? (
                <CircularProgress className="mt-2" size={25} />
              ) : (
                <>
                  <button
                    className="w-1/2 px-4 py-2 rounded border border-fortify-green text-fortify-green"
                    onClick={handleCloseInviteModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-1/2 px-4 py-2 rounded bg-fortify-green text-white"
                    onClick={handleExecuteInvite}
                  >
                    Invite
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {isRemoveModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={(e) => handleOutsideClick(e, handleCloseRemoveModal)}>
          <div className="bg-white rounded-lg p-8 w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4">Remove {memberToRemove?.first_name} {memberToRemove?.last_name}?</h2>
            <p className="mb-4">They won't be able to access your organization's Fortify portal anymore.</p>

            {removeApiError && <Alert severity="error" style={{ marginBottom: '1rem' }}>{removeApiError}</Alert>}

            <div className="flex space-x-4 justify-center">
              {isRemoveApiCallLoading ? (
                <CircularProgress className="mt-2" size={25} />
              ) : (
                <>
                  <button
                    className="w-1/2 px-4 py-2 rounded border border-fortify-green text-fortify-green"
                    onClick={handleCloseRemoveModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-1/2 px-4 py-2 rounded bg-fortify-green text-white"
                    onClick={handleExecuteRemove}
                  >
                    Remove
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      <Snackbar
        open={isErrorSnackbarOpen}
        autoHideDuration={2500}
        onClose={() => setIsErrorSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          severity="error" 
          onClose={() => setIsErrorSnackbarOpen(false)}
          sx={{
            backgroundColor: '#da4244',
            color: 'white',
            '& .MuiAlert-icon': {
              color: 'white'
            }
          }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MemberSettings;