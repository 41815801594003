import axios from '../utils/axiosSetup';

export const fetchAllRepsForSchool = async (schoolId) => {
    const response = await axios.get(`schools/${schoolId}/reps`);
    return response.data;
};

export const inviteNewRepToSchool = async (schoolId, repData) => {
    const response = await axios.post(`schools/${schoolId}/reps/invite`, repData);
    return response.data;
};

export const deleteRepAtSchool = async (schoolId, repId) => {
    const response = await axios.delete(`schools/${schoolId}/reps/${repId}`);
    return response.data;
};

export const updateRepAccessSettings = async (schoolId, repId, accessSettings) => {
    const response = await axios.patch(`schools/${schoolId}/reps/${repId}`, accessSettings);
    return response.data;
};
