import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchLoanPortfolioForSchool } from '../../api/applications';
import { PortfolioReportData } from '../../models/RepaymentData';
import { CircularProgress } from '@mui/material';
import { formatCurrency, formatCurrencyForCSV, formatDate, formatDateForCSV, formatPercent } from '../../utils/formatters';
import StatusPill from '../shared/StatusPill';
import ExportToCSV from '../shared/ExportToCSV';

const LoanPortfolio = ({ activeSchool }) => {
  const navigate = useNavigate();
  const [portfolioData, setPortfolioData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchLoanPortfolioForSchool(activeSchool.id);
        const formattedData = data.map(item => new PortfolioReportData(item));
        setPortfolioData(formattedData);
      } catch (error) {
        console.error("Error fetching loan portfolio data", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!activeSchool.has_repayments_access) {
      navigate('/dashboard');
    } else {
      fetchData();
    }
  }, [activeSchool.id, navigate, activeSchool.has_repayments_access]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  if (portfolioData.length === 0) {
    return (
      <div className="flex flex-col">
        <div className="page-title">Loan Portfolio</div>
        <hr className='my-2 line-divider'/>
        <div className="flex mt-36 items-center justify-center">
          <div className="text-center">
            <div className="text-base empty-screen-message mt-4">
              There's no data here yet.<br/>Send your Fortify application link to more students to build your school's portfolio.
            </div>
          </div>
        </div>
      </div>
    );
  }

  const exportToCSV = () => {
    const headers = [
      "Student",
      "Program",
      "Start Date",
      "Graduation Date",
      "Origination Scheduled For",
      "Originated On",
      "First Payment Due Date",
      "Earliest Owed Payment Date",
      "Tuition Financed",
      "Final Loan Amount",
      "Interest Rate",
      "Loan Term",
      "Monthly Payment",
      "Days Delinquent",
      "Past Due Amount",
      "Last Forbearance Start Date",
      "Last Forbearance End Date",
      "Total Forbearance Months",
      "Autopay Enabled",
      "Registered For Payment Portal On",
      "Last Login To Payment Portal",
      "Last Call Attempt by ZuntaFi",
      "Last Conversation with ZuntaFi",
      "Loan Counseling Completed On",
      "Current Outstanding Loan Balance",
      "Total Paid Loan Balance",
      "Tuition Received by School to Date",
      "Fortify Platform Fee Paid",
      "Total Fortify Platform Fee",
      "Fortify Portfolio Management Fee Paid",
    ];
  
    const csvData = portfolioData.map(item => [
      item.studentName,
      item.programName,
      formatDateForCSV(item.startDate),
      formatDateForCSV(item.gradDate),
      formatDateForCSV(item.originationScheduledFor),
      formatDateForCSV(item.originatedOn),
      formatDateForCSV(item.firstPaymentDueDate),
      formatDateForCSV(item.earliestOwedPaymentDate),
      formatCurrencyForCSV(item.tuitionFinanced),
      formatCurrencyForCSV(item.borrowingAmount),
      formatPercent(item.interestRate),
      item.loanTerm,
      formatCurrencyForCSV(item.monthlyPayment),
      item.daysDelinquent,
      formatCurrencyForCSV(item.pastDueAmount),
      formatDateForCSV(item.lastForbearanceBeginDate),
      formatDateForCSV(item.lastForbearanceEndDate),
      item.totalForbearanceMonths,
      item.autopayStatus === "Y" ? "Y" : "N",
      formatDateForCSV(item.registrationDate),
      formatDateForCSV(item.lastLogin),
      formatDateForCSV(item.lastCallAttemptDate),
      formatDateForCSV(item.lastCallDate),
      formatDateForCSV(item.loanCounselingCompletedOn),
      formatCurrencyForCSV(item.currentPrincipalBalance + item.currentInterestBalance),
      formatCurrencyForCSV(item.principalPaidToDate + item.interestPaidToDate),
      formatCurrencyForCSV(item.schoolTuitionReceived),
      formatCurrencyForCSV(item.fortifyPlatformFeeReceived),
      formatCurrencyForCSV(item.fortifyPlatformFee),
      formatCurrencyForCSV(item.fortifyPortfolioManagementFeeReceived)
    ]);
  
    const csvContent = [headers, ...csvData].map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      const today = new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
      link.setAttribute('download', `Fortify Loan Portfolio ${today}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="flex flex-col w-full overflow-hidden h-full">
      <div className="flex justify-between items-center mb-2">
        <div className="page-title">Loan Portfolio</div>
        <ExportToCSV exportToCSV={exportToCSV}/>
      </div>
      <hr className='mt-2 line-divider'/>
      <div className="flex-grow overflow-hidden loan-table-wrapper">
        <div className="mt-6 loan-table-container">
          <table className="loan-table">
            <thead className="bg-gray-100 sticky top-0">
              <tr className="h-12">
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Student</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Program</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Start Date</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Graduation Date</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Origination Scheduled For</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Originated On</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">First Payment Due Date</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Earliest Owed Payment Date</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Tuition Financed</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Final Loan Amount</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Interest Rate</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Loan Term</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Monthly Payment</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Days Delinquent</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Past Due Amount</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Last Forbearance Start Date</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Last Forbearance End Date</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Total Forbearance Months</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Autopay Enabled</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Registered For Payment Portal On</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Last Login To Payment Portal</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Last Call Attempt by ZuntaFi</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Last Conversation with ZuntaFi</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Loan Counseling Completed On</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Current Outstanding Loan Balance</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Total Paid Loan Balance</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Tuition Received by School to Date</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Fortify Platform Fee Paid</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Total Fortify Platform Fee</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Fortify Portfolio Management Fee Paid</th>
              </tr>
            </thead>
            <tbody>
              {portfolioData.map((item, index) => (
                <tr key={index} className="border-b h-12 cursor-pointer hover:bg-gray-100" onClick={() => navigate(`/loans/${item.loanId}`)}>
                  <td className="py-2 px-4 whitespace-nowrap">{item.studentName}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{item.programName}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.startDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.gradDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.originationScheduledFor)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.originatedOn)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.firstPaymentDueDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.earliestOwedPaymentDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.tuitionFinanced)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.borrowingAmount)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatPercent(item.interestRate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{item.loanTerm} months</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.monthlyPayment)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{item.daysDelinquent}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.pastDueAmount)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastForbearanceBeginDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastForbearanceEndDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{item.totalForbearanceMonths}</td>
                  <td className="py-2 px-4 whitespace-nowrap">
                    <StatusPill text={item.autopayStatus === "Y" ? "Yes" : "No"} backgroundColor={(item.autopayStatus === "Y") ? "bg-green-700" : "bg-red-700"} />
                  </td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.registrationDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastLogin)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastCallAttemptDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastCallDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.loanCounselingCompletedOn)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{(item.currentPrincipalBalance === null || item.currentInterestBalance === null) ? formatCurrency(item.borrowingAmount) : formatCurrency(item.currentPrincipalBalance + item.currentInterestBalance)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.principalPaidToDate + item.interestPaidToDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.schoolTuitionReceived)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.fortifyPlatformFeeReceived)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.fortifyPlatformFee)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.fortifyPortfolioManagementFeeReceived)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LoanPortfolio;
