import axios from '../utils/axiosSetup';

export const NOTIFICATION_TYPES = {
  READY_TO_CERTIFY: {
    id: 'ready_to_certify',
    label: 'A student is ready to certify',
    description: 'Get an email as soon as a student is ready to certify'
  },
  DOWN_PAYMENT_MADE: {
    id: 'down_payment_made',
    label: 'A down payment is made',
    description: 'Get an email when a student makes their down payment'
  },
  LOAN_SIGNED: {
    id: 'loan_signed',
    label: 'A new loan is signed',
    description: 'Get an email when a student signs their loan document'
  },
  NEW_STUDENT_PAST_DUE: {
    id: 'new_student_enters_past_due',
    label: 'A student enters past due',
    description: 'Get an email when a student becomes past due'
  },
  WEEKLY_DELINQUENCY_REPORTS: {
    id: 'weekly_delinquency_reports',
    label: 'Weekly delinquency reports',
    description: 'Get delinquency reports by email every Monday'
  },
  BIWEEKLY_CERTIFICATION_REMINDERS: {
    id: 'biweekly_certification_reminders',
    label: 'Biweekly certification reminders',
    description: 'Get an email Tuesdays & Thursdays if there are students to certify'
  },
  PRODUCT_NEWSLETTER: {
    id: 'product_newsletter',
    label: 'Product newsletter',
    description: 'Fortify\'s newsletter every few weeks, with new features & important updates'
  }
};

export const updateNotificationSettings = async (schoolId, notifications) => {
  const response = await axios.patch(`/schools/${schoolId}/reps/notifications`, notifications);
  return response.data;
};

export const getNotificationSettings = async (schoolId) => {
  const response = await axios.get(`/schools/${schoolId}/reps/notifications`);
  return response.data;
};