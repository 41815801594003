import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUpcomingPaymentsForSchool } from '../../api/applications';
import { UpcomingPaymentData } from '../../models/RepaymentData';
import { CircularProgress } from '@mui/material';
import { formatCurrency, formatCurrencyForCSV, formatDate, formatDateForCSV, formatPhone } from '../../utils/formatters';
import StatusPill from '../shared/StatusPill';
import ExportToCSV from '../shared/ExportToCSV';

const UpcomingPayments = ({ activeSchool }) => {
  const navigate = useNavigate();
  const [upcomingPaymentsData, setUpcomingPaymentsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchUpcomingPaymentsForSchool(activeSchool.id);
        const formattedData = data.map(item => new UpcomingPaymentData(item));
        const sortedData = formattedData.sort((a, b) => a.upcomingPaymentDate- b.upcomingPaymentDate);
        setUpcomingPaymentsData(sortedData);
      } catch (error) {
        console.error("Error fetching upcoming payments data", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!activeSchool.has_repayments_access) {
      navigate('/dashboard');
    } else {
      fetchData();
    }
  }, [activeSchool.id, activeSchool.has_repayments_access, navigate]);

  const exportToCSV = () => {
    const headers = [
      "Student",
      "Program",
      "Payment Date",
      "Monthly Payment",
      "Estimated Tuition Received",

      "Is First Payment",
      "Autopay Enabled",
      "Logged into Payment Portal",
      "In School",
      "Is Delinquent",
      "Completed Loan Counseling",

      "Email",
      "Phone",

      "Registered for Payment Portal On",
      "Last Login to Payment Portal",
      "Last Call Attempt by ZuntaFi",
      "Last Conversation with ZuntaFi",

      "Graduation Date",

      "Days Delinquent",
      "Earliest Owed Payment Date",
      "Past Due Amount",

      "Loan Counseling Scheduled For",
      "Loan Counseling Completed On",
    ];
  
    const csvData = upcomingPaymentsData.map(item => [
      item.studentName,
      item.programName,
      formatDateForCSV(item.upcomingPaymentDate),
      formatCurrencyForCSV(item.monthlyPayment),
      formatCurrencyForCSV(item.estimatedSchoolTuition),

      item.isFirstPayment ? "Y" : "N",
      item.autopayStatus === "Y" ? "Y" : "N",
      item.lastLogin !== null ? "Y" : "N",
      item.currentlyInSchool ? "Y" : "N",
      item.daysDelinquent > 0 ? "Y" : "N",
      item.loanCounselingCompletedOn !== null ? "Y" : "N",

      item.email,
      item.phone,

      formatDateForCSV(item.registrationDate),
      formatDateForCSV(item.lastLogin),
      formatDateForCSV(item.lastCallAttemptDate),
      formatDateForCSV(item.lastCallDate),

      formatDateForCSV(item.gradDate),

      item.daysDelinquent,
      formatDateForCSV(item.earliestOwedPaymentDate),
      formatCurrencyForCSV(item.pastDueAmount),

      formatDateForCSV(item.loanCounselingScheduledFor),
      formatDateForCSV(item.loanCounselingCompletedOn),
    ]);
  
    const csvContent = [headers, ...csvData].map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      const today = new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
      link.setAttribute('download', `Fortify Upcoming Payments ${today}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  if (upcomingPaymentsData.length === 0) {
    return (
      <div className="flex flex-col">
        <div className="page-title">Upcoming Payments</div>
        <hr className='my-2 line-divider'/>
        <div className="flex mt-36 items-center justify-center">
          <div className="text-center">
            <div className="text-base empty-screen-message mt-4">
              There are no upcoming payments yet.<br/>Send your Fortify application link to more students to start collecting payments.
            </div>
          </div>
        </div>
      </div>
    );
  }

  const shouldHighlight = (item) => item.daysDelinquent > 0 || (item.autopayStatus !== "Y" && !item.registrationDate);

  const hasHighlightedRows = upcomingPaymentsData.some(shouldHighlight);

  return (
    <div className="flex flex-col w-full overflow-hidden h-full">
      <div className="flex justify-between items-center mb-2">
        <div className="page-title">Upcoming Payments</div>
        <ExportToCSV exportToCSV={exportToCSV}/>
      </div>
      <hr className='mt-2 line-divider'/>
      <div className="flex-grow overflow-hidden loan-table-wrapper">
        {hasHighlightedRows && (
          <p className="text-base mt-4 text-legal-gray">
            Students are highlighted in red if they don't have autopay on and they haven't logged into their payment portal yet. Past due students are also highlighted red.
          </p>
        )}
        <div className="mt-6 loan-table-container">
          <table className="loan-table">
            <thead className="bg-gray-100 sticky top-0">
              <tr className="h-12">
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Student</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Program</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Payment Date</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Monthly Payment</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Estimated Tuition Received</th>

                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Is First Payment</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Autopay Enabled</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Logged into Payment Portal</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">In School</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Is Delinquent</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Completed Loan Counseling</th>

                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Email</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Phone</th>

                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Registered for Payment Portal On</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Last Login to Payment Portal</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Last Call Attempt by ZuntaFi</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Last Conversation with ZuntaFi</th>

                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Graduation Date</th>

                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Days Delinquent</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Earliest Owed Payment Date</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Past Due Amount</th>

                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Loan Counseling Scheduled For</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Loan Counseling Completed On</th>
              </tr>
            </thead>
            <tbody>
              {upcomingPaymentsData.map((item, index) => (
                  <tr key={index} className={`border-b h-12 cursor-pointer ${shouldHighlight(item) ? 'bg-[#ffc2c2] hover:bg-[#ff8a8a]' : 'hover:bg-gray-100'}`} onClick={() => navigate(`/loans/${item.loanId}`)}>
                    <td className="py-2 px-4 whitespace-nowrap">{item.studentName}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{item.programName}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.upcomingPaymentDate)}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.monthlyPayment)}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.estimatedSchoolTuition)}</td>

                    <td className="py-2 px-4 whitespace-nowrap">
                      <StatusPill text={item.isFirstPayment ? "Yes" : ""} backgroundColor={item.isFirstPayment ? "bg-blue-500" : ""} />
                    </td>
                    <td className="py-2 px-4 whitespace-nowrap">
                      <StatusPill text={(item.autopayStatus === "Y") ? "Yes" : "No"} backgroundColor={(item.autopayStatus === "Y") ? "bg-fortify-green" : "bg-red-700"} />
                    </td>
                    <td className="py-2 px-4 whitespace-nowrap">
                      <StatusPill text={(item.lastLogin !== null) ? "Yes" : "No"} backgroundColor={(item.lastLogin !== null) ? "bg-fortify-green" : "bg-red-700"} />
                    </td>
                    <td className="py-2 px-4 whitespace-nowrap">
                      <StatusPill text={item.currentlyInSchool ? "Yes" : "No"} backgroundColor={item.currentlyInSchool ? "bg-fortify-green" : "bg-gray-500"} />
                    </td>
                    <td className="py-2 px-4 whitespace-nowrap">
                      <StatusPill text={item.daysDelinquent > 0 ? "Yes" : "No"} backgroundColor={item.daysDelinquent > 0 ? "bg-red-700" : "bg-fortify-green"} />
                    </td>
                    <td className="py-2 px-4 whitespace-nowrap">
                      <StatusPill text={item.loanCounselingCompletedOn !== null ? "Yes" : "No"} backgroundColor={item.loanCounselingCompletedOn !== null ? "bg-fortify-green" : "bg-red-700"} />
                    </td>

                    <td className="py-2 px-4 whitespace-nowrap">
                      <a href={`mailto:${item.email}`} className="no-underline hover:underline">
                        {item.email}
                      </a>
                    </td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatPhone(item.phone)}</td>

                    <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.registrationDate)}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastLogin)}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastCallAttemptDate)}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastCallDate)}</td>

                    <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.gradDate)}</td>

                    <td className="py-2 px-4 whitespace-nowrap">{(item.daysDelinquent > 0) ? item.daysDelinquent : ""}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.earliestOwedPaymentDate)}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.pastDueAmount)}</td>

                    <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.loanCounselingScheduledFor)}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.loanCounselingCompletedOn)}</td>
                  </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UpcomingPayments;