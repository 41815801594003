import axios from '../utils/axiosSetup';

export const fetchHomepageForSchool = async (schoolId, period) => {
    const response = await axios.get(`schools/${schoolId}/homepage?period=${period}`);
    return response.data;
};

export const fetchAllLoanApplicationsForSchool = async (schoolId) => {
    const response = await axios.get(`schools/${schoolId}/loan-applications`);
    return response.data;
};

export const searchLoanApplicationsAtSchool = async (partialName, schoolId) => {
    const response = await axios.patch(`schools/${schoolId}/loan-applications/search`, { name: partialName });
    return response.data;
};

export const certifyLoanApplication = async (schoolId, loanApplicationId, certificationData) => {
    const response = await axios.patch(`schools/${schoolId}/loan-applications/${loanApplicationId}/certification`, certificationData);
    return response.data;
};

export const archiveLoanApplication = async (schoolId, loanApplicationId) => {
    const response = await axios.patch(`schools/${schoolId}/loan-applications/${loanApplicationId}/archive`);
    return response.data;
};

export const unarchiveLoanApplication = async (schoolId, loanApplicationId) => {
    const response = await axios.patch(`schools/${schoolId}/loan-applications/${loanApplicationId}/unarchive`);
    return response.data;
};

export const revertCertificationOfLoanApplication = async (schoolId, loanApplicationId) => {
    const response = await axios.patch(`schools/${schoolId}/loan-applications/${loanApplicationId}/revert-certification`);
    return response.data;
};

export const getMonthlyPayment = async (schoolId, loanApplicationId, tuition, down_payment, start_date) => {
    const response = await axios.get(`schools/${schoolId}/loan-applications/${loanApplicationId}/monthly-payment`, {
        params: { tuition, down_payment, start_date }
    });
    return response.data;
};

export const fetchStateRegulations = async () => {
    const response = await axios.get('/schools/state-regs');
    return response.data;
};

export const fetchTransactionsForSchool = async (schoolId) => {
    const response = await axios.get(`schools/${schoolId}/repayments/transactions`);
    return response.data;
};

export const fetchDisbursementsForSchool = async (schoolId) => {
    const response = await axios.get(`schools/${schoolId}/repayments/disbursements`);
    return response.data;
};

export const fetchUpcomingPaymentsForSchool = async (schoolId) => {
    const response = await axios.get(`schools/${schoolId}/repayments/upcoming-payments`);
    return response.data;
};

export const fetchLoanPortfolioForSchool = async (schoolId) => {
    const response = await axios.get(`schools/${schoolId}/repayments/portfolio`);
    return response.data;
};

export const fetchDownPaymentsForSchool = async (schoolId) => {
    const response = await axios.get(`schools/${schoolId}/repayments/down-payments`);
    return response.data;
};

export const performRiskAssessment = async (schoolId, loanApplicationId) => {
    const response = await axios.post(`schools/${schoolId}/loan-applications/${loanApplicationId}/risk-assessment`);
    return response.data;
};