import { TransactionData, PortfolioReportData } from "./RepaymentData";

class LoanApplicationData {
    constructor(data) {
      this.id = data.id;
      this.createdAt = data.created_at;
      this.schoolId = data.school_id;
      this.programId = data.program_id;
      this.studentId = data.student_id;
      this.archivedAt = data.archived_at;
      this.archiveReason = data.archive_reason;
      this.underwritingDecision = data.underwriting_decision;
      this.underwritingDecisionMadeAt = data.underwriting_decision_made_at;
      this.schoolCertification = data.school_certification;
      this.schoolCertificationMadeAt = data.school_certification_made_at;
      this.submittedAt = data.submitted_at;

      this.startDate = data.start_date;
      this.gradDate = data.grad_date;

      this.tuitionRequest = data.tuition_request;
      this.tuitionRequestStatus = data.tuition_request_status;

      this.nextStep = data.next_step;
      this.stageSummary = data.stage_summary;

      this.student = this.mapStudent(data.student);
      this.school = this.mapSchool(data.school);
      this.program = this.mapProgram(data.program);
      this.loan = this.mapLoan(data.loan);
    }

    mapStudent(studentData) {
      if (!studentData) return null;
      return {
        id: studentData.id,
        account: studentData.account ? this.mapAccount(studentData.account) : null,
        markedIneligibleAt: studentData.marked_ineligible_at,
        ineligibleReasons: studentData.ineligible_reasons,
        stripeIdentityStatus: studentData.stripe_identity_session_status,
        nameVerifiedAgainstStripe: studentData.name_verified_against_stripe,
        dobVerifiedAgainstStripe: studentData.dob_verified_against_stripe,
        ssnVerifiedAgainstStripe: studentData.ssn_verified_against_stripe,
        ofacStatus: studentData.ofac_status,
        plaidItemStatus: studentData.plaid_item_status,
        ssnIsUnique: studentData.ssn_is_unique,
        city: studentData.city,
        state: studentData.state,
      };
    }

    mapSchool(schoolData) {
      if (!schoolData) return null;
      return {
        id: schoolData.id,
        school_name: schoolData.school_name,
      };
    }

    mapProgram(programData) {
      if (!programData) return null;
      return {
        id: programData.id,
        programName: programData.program_name,
        tuition: programData.tuition,
        isTitleIV: programData.is_title_iv,
        displayWarningForCertificationsWithZeroDownPayment: programData.display_warning_for_certifications_with_zero_down_payment,
      };
    }
  
    mapAccount(accountData) {
      return {
        id: accountData.id,
        accountType: accountData.account_type,
        email: accountData.email,
        firstName: accountData.first_name,
        lastName: accountData.last_name,
        phone: accountData.phone,
      };
    }

    mapLoan(loanData) {
      return {
        id: loanData.id,
        interestRate: loanData.interest_rate,
        loanTerm: loanData.loan_term,
        tuitionFinanced: loanData.tuition_financed,
        borrowingAmount: loanData.borrowing_amount,
        requiredDownPayment: loanData.required_down_payment,
        executedOn: loanData.executed_on,
        cancelledAt: loanData.cancelled_at,
        downPaymentReceivedOn: loanData.down_payment_received_on,
        firstPaymentDueDate: loanData.first_payment_due_date,
        monthlyPayment: loanData.monthly_payment,

        originationScheduledFor: loanData.origination_scheduled_for,
        originatedOn: loanData.originated_on,

        schoolTuitionReceived: loanData.school_tuition_received,
        schoolTuitionDisbursed: loanData.school_tuition_disbursed,

        loanCounselingScheduledFor: loanData.loan_counseling_scheduled_for,
        loanCounselingCompletedOn: loanData.loan_counseling_completed_on,

        transactions: loanData.transactions ? loanData.transactions.map(transaction => new TransactionData(transaction)) : null,
        latestState: loanData.latest_state ? new PortfolioReportData(loanData.latest_state) : null,

        // keep these snake case, because it's also directly read from the API
        notes: loanData.notes ? loanData.notes.map(note => ({
          id: note.id,
          content: note.content,
          created_at: note.created_at,
          created_by_fortify: note.created_by_fortify,
          author: note.author ? this.mapAccount(note.author) : null
        })) : []
      };
    }
  }
  
  export default LoanApplicationData;