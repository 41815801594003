import React, { useState } from 'react';
import { Dialog, Button, Box, Typography, Grid, Paper } from '@mui/material';

const RiskAssessmentPurchaseModal = ({ open, onClose }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const plans = [
    { quantity: 1, pricePerUnit: 10, totalPrice: 10, savings: 0 },
    { quantity: 5, pricePerUnit: 9.25, totalPrice: 46.25, savings: 3.75 },
    { quantity: 10, pricePerUnit: 8.50, totalPrice: 85, savings: 15 },
    { quantity: 25, pricePerUnit: 8, totalPrice: 200, savings: 50 },
  ];

  const handlePurchase = () => {
    // In a real implementation, this would process the payment
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" PaperProps={{ style: { borderRadius: '15px' } }}>
      <Box p={4}>
        <Typography variant="h4" mb={3} fontWeight="bold">
          Purchase Risk Assessments
        </Typography>
        <Typography variant="body1" mb={4}>
          Risk assessments help you evaluate student applications before certification, reducing your exposure to potential defaults.
        </Typography>

        <Grid container spacing={3} mb={4}>
          {plans.map((plan) => (
            <Grid item xs={12} sm={6} md={3} key={plan.quantity}>
              <Paper
                elevation={selectedPlan?.quantity === plan.quantity ? 8 : 1}
                sx={{
                  p: 3,
                  cursor: 'pointer',
                  transition: 'all 0.2s',
                  border: selectedPlan?.quantity === plan.quantity ? '2px solid #5433FF' : '1px solid #e0e0e0',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                  }
                }}
                onClick={() => setSelectedPlan(plan)}
              >
                <Typography variant="h5" fontWeight="bold" mb={2}>
                  {plan.quantity}x
                </Typography>
                <Typography variant="body1" color="text.secondary" mb={2}>
                  ${plan.pricePerUnit.toFixed(2)} each
                </Typography>
                <Typography variant="h6" fontWeight="bold" color="primary" mb={1}>
                  ${plan.totalPrice.toFixed(2)}
                </Typography>
                {plan.savings > 0 && (
                  <Typography variant="body2" color="success.main">
                    Save ${plan.savings.toFixed(2)}
                  </Typography>
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Box display="flex" justifyContent="space-between">
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!selectedPlan}
            onClick={handlePurchase}
            sx={{ backgroundColor: 'fortify-green' }}
          >
            Purchase {selectedPlan?.quantity}x Assessments
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RiskAssessmentPurchaseModal;