import React, { useState, useEffect } from 'react';
import { Button, TextField, CircularProgress } from '@mui/material';
import { createNoteForLoan, updateNoteForLoan, deleteNoteForLoan } from '../../api/notes';
import { formatTimestamp } from '../../utils/formatters';

const NotesTab = ({ loanDetails, activeSchool, user }) => {
  const [newNote, setNewNote] = useState('');
  const [editingNote, setEditingNote] = useState(null);
  const [editContent, setEditContent] = useState('');
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [isUpdatingNote, setIsUpdatingNote] = useState(false);
  const [isDeletingNote, setIsDeletingNote] = useState(null);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    const sortedNotes = [...loanDetails.loan.notes].sort((a, b) => 
      new Date(b.createdAt) - new Date(a.createdAt)
    );
    setNotes(sortedNotes);
  }, [loanDetails.loan.notes]);

  const isNoteOwner = (note) => {
    return note.author?.email === user.email;
  };

  const handleAddNote = async () => {
    if (!newNote.trim()) return;
    
    setIsAddingNote(true);
    try {
      const updatedNotes = await createNoteForLoan(activeSchool.id, loanDetails.loan.id, newNote);
      setNotes(updatedNotes);
      setNewNote('');
    } catch (error) {
      console.error('Error adding note:', error);
    } finally {
      setIsAddingNote(false);
    }
  };

  const handleUpdateNote = async (noteId) => {
    if (!editContent.trim()) return;
    
    setIsUpdatingNote(true);
    try {
      const updatedNotes = await updateNoteForLoan(activeSchool.id, loanDetails.loan.id, noteId, editContent);
      setNotes(updatedNotes);
      setEditingNote(null);
      setEditContent('');
    } catch (error) {
      console.error('Error updating note:', error);
    } finally {
      setIsUpdatingNote(false);
    }
  };

  const handleDeleteNote = async (noteId) => {
    setIsDeletingNote(noteId);
    try {
      const updatedNotes = await deleteNoteForLoan(activeSchool.id, loanDetails.loan.id, noteId);
      setNotes(updatedNotes);
    } catch (error) {
      console.error('Error deleting note:', error);
    } finally {
      setIsDeletingNote(null);
    }
  };

  return (
    <div className="notes-tab">
      <div className="flex gap-4 items-center mb-6">
        <TextField
          fullWidth
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          placeholder="Add a new note..."
          variant="outlined"
          size="small"
          inputProps={{
            style: { 
              height: '20px',
              padding: '8px 14px'
            }
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              height: '40px'
            }
          }}
        />
        <Button 
          variant="contained" 
          onClick={handleAddNote}
          disabled={!newNote.trim() || isAddingNote}
          className="bg-fortify-blue hover:bg-blue-700"
          sx={{
            minWidth: '40px',
            width: '40px',
            height: '40px',
            padding: 0,
            fontSize: '28px'
          }}
        >
          {isAddingNote ? <CircularProgress size={20} sx={{ color: 'white' }} /> : '+'}
        </Button>
      </div>

      <div className="notes-table-container">
        <table className="loan-table">
          <thead className="sticky top-0 bg-gray-100">
            <tr className="h-12">
              <th className="py-2 px-4 border-b text-left whitespace-nowrap">Author</th>
              <th className="py-2 px-4 border-b text-left whitespace-nowrap">Date</th>
              <th className="py-2 px-4 border-b text-left whitespace-nowrap">Note</th>
              <th className="py-2 px-4 border-b text-left whitespace-nowrap"></th>
            </tr>
          </thead>
          <tbody>
            {notes.map((note) => (
              <tr key={note.id} className="border-b h-12">
                <td className="py-2 px-4 whitespace-nowrap">
                  {note.created_by_fortify ? 'Fortify' : `${note.author?.first_name || 'Unknown'} ${note.author?.last_name || 'Author'}`}
                </td>
                <td className="py-2 px-4 whitespace-nowrap">{formatTimestamp(note.created_at)}</td>
                <td className="py-2 px-4" style={{ maxWidth: '400px' }}>
                  {editingNote === note.id ? (
                    <TextField
                      fullWidth
                      value={editContent}
                      onChange={(e) => setEditContent(e.target.value)}
                      size="small"
                      inputProps={{
                        style: { 
                          height: '20px'
                        }
                      }}
                    />
                  ) : (
                    <div className="break-words">{note.content}</div>
                  )}
                </td>
                <td className="py-2 px-4 whitespace-nowrap">
                  {isNoteOwner(note) && (
                    <>
                      {editingNote === note.id ? (
                        <div className="flex gap-2">
                          {isUpdatingNote ? (
                            <CircularProgress size={20} />
                          ) : (
                            <>
                              <button 
                                onClick={() => handleUpdateNote(note.id)}
                                className="w-6 h-6"
                              >
                                <img src="/loanDetails/saveNote.svg" alt="Save" className="w-full h-full" />
                              </button>
                              <button 
                                onClick={() => setEditingNote(null)}
                                className="w-6 h-6"
                              >
                                <img src="/loanDetails/cancelNote.svg" alt="Cancel" className="w-full h-full" />
                              </button>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className="flex gap-2">
                          {isDeletingNote === note.id ? (
                            <CircularProgress size={20} />
                          ) : (
                            <>
                              <button 
                                onClick={() => {
                                  setEditingNote(note.id);
                                  setEditContent(note.content);
                                }}
                                className="w-6 h-6"
                              >
                                <img src="/loanDetails/editNote.svg" alt="Edit" className="w-full h-full" />
                              </button>
                              <button 
                                onClick={() => handleDeleteNote(note.id)}
                                className="w-6 h-6"
                              >
                                <img src="/loanDetails/deleteNote.svg" alt="Delete" className="w-full h-full" />
                              </button>
                            </>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NotesTab;