import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchDownPaymentsForSchool } from '../../api/applications';
import { DownPaymentData } from '../../models/RepaymentData';
import { CircularProgress } from '@mui/material';
import { formatCurrency, formatCurrencyForCSV, formatDate, formatDateForCSV } from '../../utils/formatters';
import ExportToCSV from '../shared/ExportToCSV';

const DownPayments = ({ activeSchool }) => {
  const navigate = useNavigate();
  const [downPaymentData, setDownPaymentData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchDownPaymentsForSchool(activeSchool.id);
        const formattedData = data.map(item => new DownPaymentData(item));
        const sortedData = formattedData.sort((a, b) => b.receivedOn - a.receivedOn);
        setDownPaymentData(sortedData);
      } catch (error) {
        console.error("Error fetching down payment data", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!activeSchool.has_accounting_access) {
      navigate('/dashboard');
    } else {
      fetchData();
    }
  }, [activeSchool.id, activeSchool.has_accounting_access, navigate]);

  const exportToCSV = () => {
    const headers = [
      "Student",
      "Program",
      "Amount",
      "Received On",
    ];
  
    const csvData = downPaymentData.map(item => [
      item.studentName,
      item.programName,
      formatCurrencyForCSV(item.amount),
      formatDateForCSV(item.receivedOn),
    ]);
  
    const csvContent = [headers, ...csvData].map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      const today = new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
      link.setAttribute('download', `Fortify Down Payments ${today}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  if (downPaymentData.length === 0) {
    return (
      <div className="flex flex-col">
        <div className="page-title">Down Payments</div>
        <hr className='my-2 line-divider'/>
        <div className="flex mt-36 items-center justify-center">
          <div className="text-center">
            <div className="text-base empty-screen-message mt-4">
              You don't have any down payments yet.
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full overflow-hidden h-full">
      <div className="flex justify-between items-center mb-2">
        <div className="page-title">Down Payments</div>
        <ExportToCSV exportToCSV={exportToCSV} />
      </div>
      <hr className='my-2 line-divider'/>
      <div className="text-base mt-4 text-legal-gray">
        Remember, students might dispute or charge back down payments. Please refer to <a href="https://dashboard.stripe.com" target="_blank" rel="noopener noreferrer" className="text-[#5433ff] underline hover:text-[#5433ff] hover:no-underline">Stripe</a> for the most accurate data on down payments.
      </div>
      <div className="flex-grow overflow-hidden loan-table-wrapper">
        <div className="mt-6 loan-table-container">
          <table className="loan-table">
            <thead className="bg-gray-100 sticky top-0">
              <tr className="h-12">
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Student</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Program</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Amount</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Received On</th>
              </tr>
            </thead>
            <tbody>
              {downPaymentData.map((item, index) => (
                <tr key={index} className="border-b h-12 cursor-pointer hover:bg-gray-100" onClick={() => navigate(`/loans/${item.loanId}`)}>
                  <td className="py-2 px-4 whitespace-nowrap">{item.studentName}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{item.programName}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.amount)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.receivedOn)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DownPayments;