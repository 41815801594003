import StageBox from "./StageBox";

export const completionStatusBox = (data) => {
    const {
        student: { markedIneligibleAt, ineligibleReasons, state },
        submittedAt,
        archivedAt,
        tuitionRequestStatus,
    } = data;

    if (markedIneligibleAt) {
        let ineligibilityText = "Student is ineligible";
        
        if (ineligibleReasons && ineligibleReasons.length > 0) {
            if (ineligibleReasons.includes("age_under_18")) {
                ineligibilityText = "Student is ineligible (under 18 years old)";
            } else if (ineligibleReasons.includes("residency_status")) {
                ineligibilityText = "Student is ineligible (non-US resident)";
            } else if (ineligibleReasons.includes("state_of_residence")) {
                ineligibilityText = `Student is ineligible (${state} resident)`;
            }
        }

        return (
            <StageBox 
                stage={"error"}
                text={ineligibilityText}
            />
        )
    }

    if (submittedAt && tuitionRequestStatus !== "pending_student_request") {
        return (
            <StageBox 
                stage={archivedAt ? "archived" : "success"}
                text={"Application completed"}
            />
        )
    }

    if (submittedAt) {
        return (
            <StageBox 
                stage={archivedAt ? "archived" : "incomplete"}
                text={"Approved, pending tuition request"}
            />
        )
    }

    return (
        <StageBox 
            stage={archivedAt ? "archived" : "incomplete"}
            text={"Application not submitted"}
        />
    )
};


export const certificationStatusBox = (data) => {
    const {
        student: { markedIneligibleAt },
        submittedAt,
        archivedAt,
        tuitionRequestStatus,
        schoolCertification,
    } = data;

    if (
        schoolCertification === "approved"
        && tuitionRequestStatus !== "pending_school_approval"
    ) {
        return (
            <StageBox 
                stage={archivedAt ? "archived" : "success"}
                text={"Application certified"}
            />
        )
    }

    if (
        markedIneligibleAt !== null ||
        submittedAt === null ||
        tuitionRequestStatus === "pending_student_request"
    ) {
        return (
            <StageBox 
                stage={archivedAt ? "archived" : "incomplete"}
                text={"Not yet certified"}
            />
        );
    }
    
    return (
        <StageBox 
            stage={archivedAt ? "archived" : "actionRequired"}
            text={"Needs certification"}
        />
    );

};

export const verificationStatusBox = (data) => {
    const {
        student: { 
            ofacStatus,
            plaidItemStatus,
            stripeIdentityStatus,
            nameVerifiedAgainstStripe,
            dobVerifiedAgainstStripe,
            ssnVerifiedAgainstStripe,
            ssnIsUnique,
        },
        archivedAt,
    } = data;

    if (
        ofacStatus === "verified" &&
        ["verified", "skipped"].includes(plaidItemStatus) &&
        stripeIdentityStatus === "verified" &&
        nameVerifiedAgainstStripe &&
        dobVerifiedAgainstStripe &&
        ssnVerifiedAgainstStripe &&
        ssnIsUnique
    ) {
        return (
            <StageBox 
                stage={archivedAt ? "archived" : "success"}
                text={"ID verified"}
            />
        );
    }

    const bankStatesWithoutFurtherStudentAction = ["pending_automatic_verification", "verified", "skipped"]
    const identityStatesWithoutFurtherStudentAction = ["processing", "verified"]

    if (
        bankStatesWithoutFurtherStudentAction.includes(plaidItemStatus) &&
        identityStatesWithoutFurtherStudentAction.includes(stripeIdentityStatus)
    ) {
        // not verified, and no student action, so it's pending Fortify's action
        return (
            <StageBox 
                stage={archivedAt ? "archived" : "actionRequired"}
                text={"Pending manual ID verification"}
            />
        );
    }

    return (
        <StageBox 
            stage={archivedAt ? "archived" : "incomplete"}
            text={"Pending ID verification"}
        />
    );
};

// If no down payment is required, this DOES NOT RENDER
// Pending down payment (incomplete) (clock.svg)
export const downPaymentStatusBox = (data, requiredDownPaymentFloat) => {
    const {
        loan: { 
            downPaymentReceivedOn,
        },
        archivedAt,
    } = data;

    const formattedDownPayment = requiredDownPaymentFloat % 1 === 0
        ? `$${requiredDownPaymentFloat.toFixed(0)}`
        : `$${requiredDownPaymentFloat.toFixed(2)}`;

    if (downPaymentReceivedOn === null) {
        return (
            <StageBox
                stage={archivedAt ? "archived" : "incomplete"}
                text={`Pending ${formattedDownPayment} down payment`}
            />
        )
    }

    return (
        <StageBox
            stage={archivedAt ? "archived" : "success"}
            text={`Paid ${formattedDownPayment} down payment`}
        />
    )
};

export const signatureStatusBox = (data) => {
    const {
        loan: { 
            executedOn,
            cancelledAt,
        },
        archivedAt,
    } = data;

    if (cancelledAt) {
        return (
            <StageBox
                stage="error"
                text={"The loan was cancelled after signature."}
            />
        )
    }

    if (executedOn === null) {
        return (
            <StageBox 
                stage={archivedAt ? "archived" : "incomplete"}
                text={"Pending loan signature"}
            />
        );
    }

    return (
        <StageBox 
            stage="success"
            text={"Loan contract signed"}
        />
    );
};